import { Divider } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "../components.module.css";

const Summary = ({
  title,
  referenceNumber,
  policyHolder,
  policyHolderSurname,
  premiumAmount,
}) => {
  return (
    <div className={style.summary_container}>
      <Row className="justify-content-center">
        <Col lg={12}>
          <h3 className="primary-color f-700 text-center">Hurray!</h3>
          <p className="primary-color f-700 text-center">
            All that's left is payment.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-4">
        <Col>
          <p className="primary-color f-700 text-left">Reference number :</p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">
            {referenceNumber}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col>
          <p className="primary-color f-700 text-left">Policy holder :</p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">
            {policyHolder} {policyHolderSurname}
          </p>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-between mt-4">
        <Col>
          <p className="primary-color f-700 text-left">
            Annual Premium (VAT Inclusive):
          </p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">
            TSh{" "}
            {premiumAmount
              ?.toFixed(2)
              ?.toString() 
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  
          </p>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-between mt-3">
        <p className="primary-color f-300  w-100">
          Please use any of the following payment methods to submit your premium
          payment to complete this application process.
        </p>
      </Row>

      <Row className="justify-content-between mt-3">
        <Col lg={12}>
          <p className="primary-color f-700  w-100">Bank details</p>
          <ul>
            <li className="primary-color">
              <span className="primary-color f-300  w-100">Bank:</span>{" "}
              <span className="primary-color f-700  w-100">
                Letshego Bank (T) Limited
              </span>
            </li>
            <li className="primary-color">
              <span className="primary-color f-300  w-100">Account Name:</span>{" "}
              <span className="primary-color f-700  w-100">
                SANLAM GENERAL INSURANCE LTD
              </span>
            </li>
            <li className="primary-color">
              <span className="primary-color f-300  w-100">
                Account Number:
              </span>{" "}
              <span className="primary-color f-700  w-100"> 21710116058</span>
            </li>
            <li className="primary-color">
              <span className="primary-color f-300  w-100"> Branch Code:</span>{" "}
              <span className="primary-color f-700  w-100">
                100
              </span>
            </li>
            <li className="primary-color">
              <span className="primary-color f-300  w-100">
                {" "}
                Swift Code:
              </span>{" "}
              <span className="primary-color f-700  w-100">
                ADVBTZTZ
              </span>
            </li>

            <li className="primary-color">
              <span className="primary-color f-300  w-100">
                {" "}
                Premium Amount:
              </span>{" "}
              <span className="primary-color f-700  w-100">
              TSh{" "}
            {premiumAmount
              ?.toFixed(2)
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </li>

            <li className="primary-color">
              <span className="primary-color f-300  w-100">
                {" "}
                Reference number :
              </span>{" "}
              <span className="primary-color f-700  w-100">
                {referenceNumber}
              </span>
            </li>
          </ul>
        </Col>
      </Row>

  <Row className="justify-content-between mt-3">
        <Col lg={12}>
          <p className="primary-color f-700  w-100"> To Pay Direct from Your Wallet</p> 
          </Col>
      </Row>  
    <Row className="justify-content-between mt-1">
        <Col lg={12}>
          <p className="primary-color f-700  w-100">Mpesa details :</p>
          <ul>
            {Mpesa.map((d) => (
                <li className="primary-color">
                  <span className="primary-color f-300  w-100"> {d}</span>
                 </li>
              ))}
          </ul>
        </Col>
      </Row>
      <Row className="justify-content-between mt-3">
        <Col lg={12}>
          <p className="primary-color f-700  w-100">TigoPesa details :</p>
          <ul>
            {TigoPesa.map((d) => (
                <li className="primary-color">
                  <span className="primary-color f-300  w-100"> {d}</span>
                 </li>
              ))}
       </ul>
        </Col>
      </Row>

      <Row className="justify-content-between mt-3">
        <Col lg={12}>
          <p className="primary-color f-700  w-100">AirtelMoney details :</p>
          <ul>
            {AirtelMoney.map((d) => (
                <li className="primary-color">
                  <span className="primary-color f-300  w-100"> {d}</span>
                 </li>
              ))}
       </ul>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
          <p className="primary-color f-700 text-left m-0 w-100">
            Support contact details:
          </p>
          <ul>
            <li className="primary-color f-500 text-left m-0 w-100">
              tz.insurance@letshego.com
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
              022 222 5000
            </li>
          </ul>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
          <p className="primary-color f-700 text-left m-0 w-100">
            Alternative details:
          </p>
          <ul>
            <li className="primary-color f-500 text-left m-0 w-100">
            info.tz@letshego.com
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
            +255 769 579 357
            </li>
          </ul>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-start mt-3">
        <p className="primary-color f-700 text-center">
          Use the navigation bar on the left to return to the home page
        </p>
      </Row>
    </div>
  );
};

export default Summary;


const Mpesa = [
  "Dial *150*00*",
  `Select 1 "Send Money"`,
  `Select 4 "To Bank" `,
  `Select 7 "G to L"`, 
  `Select 7 "Letshego"`,
  `Select 1 "Enter Reference"`,
  `Enter "21710116058"`,
  "Enter Amount",
  "Enter PIN",
  "Select 1 to Accept"
];

const TigoPesa = [
  "Dial *150*01*",
`Select 7 "Financial Services"`,
`Select 1 "TigoP to Bank"`,
`Select 10 "Other Banks"`,
`Select 7 "Letshego"`,
`Select 1 "Enter Account Number"`,
`Enter "21710116058"`,
"Enter Amount",
"Enter PIN",
"Select 1 to Accept",

];

const AirtelMoney =[
  "Dial *150*60*",
  `Select 1 "Send Money"`,
  `Select 4 "Send to Bank"`,
  `Select 13"Letshego"`,
  `Enter "21710116058"`,
  "Enter Amount",
  "Enter PIN",
  "Select 1 to Accept"
  
];