import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// Import image from assets folder
import { main, sanlam, Fullmain, Car } from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  clearState,
} from "../../store/actions/products";
import {
  getAgentOnboarding,
  login,
} from "../../store/actions/auth";
import Loader from "../../components/Loader";
import {trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Homepage({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();

  const agentCode = useSelector((state) => state.auth.agentCode);

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );
  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;
 
  
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  const handleSubmit = async (name) => {
    try{
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductTypeDetails());
      riskRes?.risks?.forEach(async (risk, index) => {
        await dispatch(getProductRisksAttributes(risk.instanceId));
        if (riskRes?.risks?.length - 1 === index) {
          history("/forms");
          setIsLoading(false);
        }
      });
      setIsCounter(false);
      //trackUserEvents
      trackUserEvents(name, {});
      return;
    }catch(err){
      console.log(err);
    }
  }

    return (
      <div>
        {width > 1024 ? (
          <Container>
            <div className="homepage-banner-pattern">
              <Row className="py-3 ">
                <Col lg={5} xs={12} className="text-center main__img_container">
                  <div>
                    <img className="main__img" src={Fullmain} alt="main" />
                  </div>
                </Col>
                <Col lg={7} xs={12} className="homepage__main__text">
                  <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                    Motor Care
                  </Row>
                  <Row
                    className="homepage1__heading2 m-0 pt-1 justify-content-center"
                    style={{ marginLeft: "16px" }}
                  >
                    Motor Private Insurance to cover your car for unforseen
                    accidents
                  </Row>
                  <Row className="py-4 m-0 justify-content-center">
                    <Col xs={6} md={4} className="p-0">
                      {width > 700 && (
                        <BodyButton
                          color="white"
                          bg={width > 770 ? "#FBD105" : "#FBD105"}
                          name={width > 770 ? "Buy Cover Now" : "Buy Cover Now"}
                          onClick={() => {
                            handleSubmit("tz_motor_get_a_quote_cta_top");
                          }}
                          size={16}
                          weight={700}
                          marginT={0}
                          marginR={width > 770 ? "auto" : 0}
                          marginL={width > 770 ? "auto" : 0}
                          width={width > 770 ? "180px" : "100%"}
                          align={width > 770 ? "left" : "center"}
                          black={width > 430 ? false : true}
                          borderTRR={25}
                          borderBLR={25}
                          borderBRR={25}
                          borderTLR={25}
                          br={100}
                          isHeader={true}
                          rounded={true}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row className="mt-0">
              <Col sm={12}>
                <Card2 width={width} />
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="homepage-banner-pattern">
            <Row
              className="py-3 justify-content-center"
              style={{ margin: "15px" }}
            >
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                  <h1
                    style={{
                      fontSize: "29px",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    Motor Care
                  </h1>
                </Row>
                <Row
                  className="homepage1__heading2 m-0 pt-1 justify-content-center"
                  style={{ marginLeft: "14px", fontSize: "13px" }}
                >
                  Motor Private Insurance to cover your car for unforseen
                  accidents
                </Row>
              </Col>
              <Col
                lg={5}
                xs={12}
                className="text-center main__img_container"
                style={{ marginBottom: "30px" }}
              >
                <img className="main__img" src={Fullmain} alt="main" />
              </Col>
              <Row
                className="justify-content-center"
                style={{ backgroundColor: "#59599A", borderRadius: "5px" }}
              >
                <Col lg={7} xs={12} className="homepage__main__text">
                  <Row className="py-4 m-0 justify-content-center">
                    <Col
                      xs={6}
                      md={4}
                      className="p-0"
                      style={{ position: "absolute", marginTop: "-45px" }}
                    >
                      {width < 700 && (
                        <BodyButton
                          color="white"
                          bg={width > 770 ? "#FBD105" : "#FBD105"}
                          name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                          onClick={() => {
                            handleSubmit("tz_motor_get_a_quote_mobile_top");
                          }}
                          size={16}
                          rounded={true}
                          weight={700}
                          marginT={0}
                          marginR={width < 770 ? 0 : 50}
                          borderTRR={25}
                          borderBLR={25}
                          borderBRR={25}
                          borderTLR={25}
                          width={width < 770 ? "auto" : "100%"}
                          align={width < 770 ? "left" : "center"}
                          black={width < 430 ? false : true}
                          br={100}
                          isHeader={true}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <h4
                    style={{
                      fontSize: "20px",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    Private Motor Insurance
                  </h4>
                </Row>
                <Row>
                  <h6
                    style={{
                      fontSize: "10pt",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Cover options to choose from
                  </h6>
                </Row>
                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
                <Row style={{ marginTop: "8px" }}>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "10pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "1px",
                      marginTop: "1px",
                    }}
                  >
                    {/* Comprehensive claim free Vehicle  */}
                    Comprehensive
                  </h6>
                </Row>
                <Row>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "10pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "8px",
                      marginTop: "1px",
                    }}
                  >
                    From: TSh 250,000
                  </h6>
                </Row>
                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
                <Row style={{ marginTop: "8px" }}>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "10pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "1px",
                      marginTop: "1px",
                    }}
                  >
                    3rd party fire and theft
                  </h6>
                </Row>
                <Row>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "10pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "8px",
                      marginTop: "1px",
                    }}
                  >
                    From: TSh 200,000
                  </h6>
                </Row>
                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
                <Row style={{ marginTop: "8px" }}>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "10pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "1px",
                      marginTop: "1px",
                    }}
                  >
                    3rd party
                  </h6>
                </Row>
                <Row>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "10pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "8px",
                      marginTop: "1px",
                    }}
                  >
                    From: TSh 100,000
                  </h6>
                </Row>
                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
                <div style={{ height: "20px" }} />
                <Row className="py-2 m-0 justify-content-center">
                  <Col xs={6} md={4} className="p-0">
                    {width < 700 && (
                      <BodyButton
                        color="white"
                        bg={width < 770 ? "#FBD105" : "#FBD105"}
                        name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                        onClick={() => { handleSubmit("tz_motor_get_a_quote_mobile_bottom");}}
                        size={14}
                        weight={700}
                        marginT={0}
                        rounded={true}
                        borderTRR={25}
                        borderBLR={25}
                        borderBRR={25}
                        borderTLR={25}
                        marginR={width < 770 ? 0 : 50}
                        width={width < 770 ? "108%" : "100%"}
                        align={width < 770 ? "left" : "center"}
                        black={width < 430 ? false : true}
                        br={100}
                        isHeader={true}
                      />
                    )}
                  </Col>
                </Row>

                <div style={{ height: "20px" }} />
                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
                <Row
                  className="justify-content-center"
                  style={{
                    fontSize: "8pt",
                    color: "white",
                    textAlign: "center",
                    marginBottom: "5px",
                    padding: "14px",
                  }}
                >
                  By opting to buy insurance, Letshego will share the required
                  information with the insurer to facilitate your policy
                  purchase.
                </Row>
                <Row>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "8pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    Policies underwritten by
                  </h6>
                </Row>
                <Row>
                  <div
                    className={`d-flex ${
                      width > 770
                        ? "justify-content-start"
                        : "justify-content-center"
                    }`}
                  >
                    <img
                      src={sanlam}
                      style={{
                        marginBottom: 20,
                        width: "87px",
                      }}
                      alt="sanlam"
                    />
                  </div>
                </Row>
              </Row>
            </Row>
          </div>
        )}
        {isLoading && (
          <div
            style={{
              height: "99vh",
              width: "100%",
              zIndex: 100,
              backgroundColor: "#00000050",
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "0px",
            }}
          >
            <div style={{ height: 100, width: 100 }}>
              <CircularProgressbar
                styles={buildStyles({
                  textColor: "#fbcf2c",
                  pathColor: "#fbcf2c",
                })}
                value={countDown}
                text={`${countDown}%`}
              />
            </div>
          </div>
        )}
      </div>
    );
}

export default Homepage;
