import classNames from "classnames";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  ConfirmationBox,
  Info,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import { storeState } from "../../../store/actions/prevState";
import {
  updateCustomAttributes,
} from "../../../store/actions/products";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import { trackUserEvents } from "../../../utils/utils";

const Step4 = ({ handleNext, handleBack }) => {
  const {width} = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);

  const productOptions = useSelector(state => state.products?.productOptions?.productOptions);

  const dispatch = useDispatch();

  const terms = useSelector((state) => state?.products?.terms);

  const navigate = useNavigate();

  
   const handleBackTrack = () => {
     //trackUserEvents
     trackUserEvents("tz_select_cover_payment_back_cta", {});
     handleBack();
   };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">
              Motor Private Insurance Cover
            </h3>
            <h5 className="primary-color f-700">Select your cover</h5>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Motor Private Insurance Cover</p>
          <h5 className="primary-color f-700">Policy Application</h5>
        </div>
      )}

      <Container style={{ maxWidth: "460px" }}>
        <div className="d-flex justify-content-center align-items-start flex-column mt-5">
          <h5 className="primary-color f-700">You're almost there</h5>
          <Info>
            <p className="primary-color f-700">
              Your application will be submitted to the insurer for approval.
              Once payment is confirmed they will send you your policy
              documents.
            </p>
          </Info>
          <div style={{ height: "20px" }} />
          <ConfirmationBox
            value={terms}
            onChange={(e) =>
              dispatch(
                updateCustomAttributes({
                  type: "TERMS",
                  payload: e.target.checked,
                })
              )
            }
          >
            <p className="primary-color">
              {" "}
              I confirm that I have read and understood the{" "}
              <a
                rel="noreferrer noopener"
                href="docs/Motor Private Book.pdf"
                download
              >
                terms and conditions
              </a>{" "}
              governing the provision of motor private insurance services, and
              agree to be bound by them. I accept Letshego seeking any
              information from previous insurers, who have previously received
              an application from myself.
            </p>
          </ConfirmationBox>
          <div style={{ height: "20px" }} />
        </div>
      </Container>
      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">
            Total annual premium (VAT Inclusive)
          </h5>
          <h5 className="text-white f-700">{`TSh ${productOptions
            .map((ele, index) => ele.risks[0] && ele.risks[0].rating.total)[0]
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        payment={true}
        isBackDisabled={isLoading}
        next={() => {
          setIsLoading(true);
          if (!terms) {
            //trackUserEvents
            trackUserEvents("tz_select_cover_payment_cta", {});
            return toast.error("Please confirm terms & conditions");
          }
          trackUserEvents("tz_select_cover_payment_cta", {});
          setIsLoading(false);
          dispatch(storeState());
          navigate("/payment-success");
        }}
      />

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

export default Step4;
