import "./App.css";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "./screens/Forms/Forms";
import Homepage from "./screens/Homepage/Home";
import "./components/common.css";
import PaymentSuccess from "./screens/Forms/Steps/PaymentSuccess";
import { useEffect, useState } from "react";
import useWindowDimensions from "./utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import { getAgentDetails, login, updateLoading } from "./store/actions/auth";
import { clearRiskState, clearState } from "./store/actions/products";
import Loader from "./components/Loader";
import { parseQuery, setParam } from "./utils/utils";

console.log = () => {};
console.error = () => {};
console.warn = () => {};

function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const companyDetails = useSelector((state) => state.auth.companyDetails);
  const location = useLocation();
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  function parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  const [ishomeLoading, setIsHomeLoading] = useState(true);
  const agentCode = useSelector((state) => state.auth.agentCode);

  
  useEffect(() => {
    if (location.pathname !== "/forms" && location.pathname !== "/") {
      dispatch(clearRiskState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Loader state form
  const isLoading = useSelector((state) => state.auth.loading);


  return (
    <Routes>
      <Route path="/forms" element={<Form />} />
      <Route path="/" element={<Homepage />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
    </Routes>
  );
}

export default App;
