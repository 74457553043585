import React from 'react';
import { payNow } from '../assets/images';
import style from './components.module.css';

const NavigationButtons = ({
    back,
    next,
    payment,
    disabled=false,
    isBackDisabled = false,
    loading
}) => {
    return(
        <div className={style.navigationButtons}>
            <button disabled={isBackDisabled} className={isBackDisabled ? style.navigationButtons_back_disabled : style.navigationButtons_back} onClick={back}>
               {'< Back'}
            </button>
            {/* {disabled ? <></> : <button className={style.navigationButtons_next} onClick={() => {
                next();
            } }>
               {payment ? 'Submit' : 'Next >'}
            </button>} */}
            {disabled ? <></> : <button className={style.navigationButtons_next} onClick={() => {
                next();
            } }>               
                {loading ? <div class="lds-ring"><div></div><div></div><div></div></div> : (payment ? 'Submit' : 'Next >')}
            </button>}
        </div>
    )
}

export default NavigationButtons;