import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import {
  DateInput,
  FileUpload,
  NumberInput,
  Select,
  TextInput,
  TwoButtons,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  CreatePolicy,
  policySaleAttachments,
  policyDropdownOptions,
  updateAccordion,
  updateCustomAttributes,
  updatedAttributes,
  approvePolicy,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import CONSTANTS, { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import { trackUserEvents } from "../../../utils/utils";


const Step2 = ({ handleNext, handleBack }) => {
  const driverState = useSelector((state) => state.products?.driverAttributes);
  const policyAtt = useSelector(
    (state) => state.products?.policyAttachments.attachments
  );
  const policyAttOpt = useSelector(
    (state) => state.products?.policyAttachmentsOptions
  );
  const policyDropOpt = useSelector(
    (state) => state.products?.policyDropdownOptions
  );
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => {
    updateState({});
  }, []);

  const [driverAttributes, setDriverAttributes] = useState(driverState);
  const [errors, setErrors] = useState({});
  const productOptions = useSelector(
    (state) => state.products?.productOptions?.productOptions
  );
   const [showErrorModal, setShowErrorModal] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
  // External reference

  const riskAttributes = useSelector((state) =>
    state.products?.riskAttributes?.map((x) => ({
      ...x,
      attributes: x.attributes.map((y) => ({ ...y, code: x.code })),
    }))
  );

  const selectedCoverValue = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "INPR")
        ?.attributes[0]?.value
  );
  var filterRiskAttributes = (code) => {
    let attrByCode = riskAttributes.filter((ra) => ra.code === code)[0];
    return attrByCode.attributes.map((d) => {
      return {
        name: d.name,
        value:
          d.dataType === 7 && !d.value
            ? JSON.parse(d.settings).items[d.defaultValue]
            : d.value,
      };
    });
  };

  const productOptionRef = useSelector(
    (state) => state.products?.productOptions?.productOptions
  );

  const unqriskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "UNQ")?.attributes
  );

  const unqgetAttributes = (value) => {
    return unqriskAttributes?.find((x) => x.name === value);
  };

  const driverRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "DRI")?.attributes
  );

  const getDriAttributes = (value) => {
    return driverRiskAttributes.find((x) => x.name === value);
  };

  const driverCoverAttribute = riskAttributes.find((x) => x.code === RISKS.DRI);
  const docArr = ["VRC", "PHF", "PHR", "PLS", "PRS", "POB", "PHD", "PCN"];

  const underWritingAttribute = riskAttributes.find(
    (x) => x.code === RISKS.UNQ
  );

  const dispatch = useDispatch();

  const handleChange = (evt, instanceId, code) => {
    var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
    var arr = [...attribute];
    var index = attribute.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, code));
    dispatch(
      updateCustomAttributes({
        type: "MAINMEMBER",
        payload: [{ ...mainMemberState[0], [arr[index].name]: evt }],
      })
    );
  };

  const handleChangeQuestions = (evt, instanceId) => {
    var arr = [...unqriskAttributes];
    var index = unqriskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "UNQ"));
    dispatch(
      updateCustomAttributes({
        type: "MAINMEMBER",
        payload: [{ ...mainMemberState[0], [arr[index].name]: evt }],
      })
    );
  };

  const handleCustomDriverChange = (evt, name, index) => {
    var arr = [...driverAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setDriverAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "DRIVER_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const calculateMinDate = (type) => {
    const date = new Date();
    if (type === "child") {
      date.setFullYear(date.getFullYear() - 24);
    } else {
      date.setFullYear(date.getFullYear() - 80);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateMaxDate = (type) => {
    const date = new Date();
    if (type === "child") {
      return new Date();
    } else {
      date.setFullYear(date.getFullYear() - 18);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const renderComponents = useCallback(
    (item, custom, index) => {
      switch (item.dataType) {
        case 1:
          return (
            <TextInput
              id={item.name}
              label={item.description}
              errors={
                custom ? errors[`${item.name} ${index + 1}`] : errors[item.name]
              }
              name={item.name}
              value={item?.value}
              onChange={(e) =>
                handleChange(e.target.value, item.instanceId, item.code)
              }
              required={
                item.instanceId === "0d26d453-f4ae-203b-7be1-60b002971008"
                  ? true
                  : item.isRequired
              }
              placeholder={item.placeholder}
            />
          );
        case 2:
          return (
            <NumberInput
              id={item.name}
              label={item.description}
              errors={errors[item.name]}
              name={item.name}
              placeholder={"+255123456789"}
              value={item?.value}
              onChange={(e) =>
                handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 5:
          return (
            <DateInput
              id={item.name}
              label={item.description}
              errors={errors[item.name]}
              name={item.name}
              minDate={calculateMinDate(custom)}
              maxDate={calculateMaxDate(custom)}
              value={item?.value}
              onChange={(e) =>
                handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 7:
          return (
            <Select
              id={item.name}
              title={item.description}
              half={true}
              name={item.name}
              width={150}
              options={JSON.parse(item.settings)?.items?.map((x) => ({
                name: x,
                value: x,
              }))}
              value={item?.value}
              defaultValue={item?.value ?? JSON.parse(item.settings)?.items[0]}
              onClick={(e) => handleChange(e, item.instanceId, item.code)}
              required={item.isRequired}
            />
          );
        default:
          return null;
      }
    },
    [errors, riskAttributes]
  );
  const { width } = useWindowDimensions();

  const accordionState = useSelector((state) => state?.products?.accordion);

  const [expand, setExpand] = useState({
    ...accordionState,
  });

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);
  const [isLoading, setIsLoading] = useState(false);

  const getConstraints = () => {
    var val = {};
    var attributes_name = [];
    selectedCoverValue === "Third Party Only"
      ? (attributes_name = [
          { name: "firstName" },
          { name: "lastName" },
          { name: "email" },
          { name: "number" },
          { name: "occupation" },
          { name: "nationality" },
          { name: "Vehicle registration number" },
          { name: "carImages0" },
          { name: "mainGender" },
          { name: "address" },
          { name: "city" },
          { name: "state" },
          { name: "document_of_proof" },
          ...riskAttributes.find((x) => x.code === "DRI")?.attributes,
          ...unqriskAttributes.map((att, index) => {
            return { name: att.name };
          }),
        ])
      : (attributes_name = [
          { name: "firstName" },
          { name: "lastName" },
          { name: "email" },
          { name: "number" },
          { name: "occupation" },
          { name: "nationality" },
          { name: "mainGender" },
          { name: "address" },
          { name: "city" },
          { name: "state" },
          { name: "Vehicle registration number" },
          ...Array(8)
            .fill()
            .map((_, index) => {
              return {
                name: `carImages${index}`,
              };
            }),
          { name: "document_of_proof" },
          ...riskAttributes.find((x) => x.code === "DRI")?.attributes,
          ...unqriskAttributes.map((att, index) => {
            return { name: att.name };
          }),
        ]);

    attributes_name.forEach((y, i) => {
      if (y.name === "number") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
              message: "^ Invalid mobile number",
            },
          },
        };
      } else if (y.name === "ID Number") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      } else if (y.name === "E-Mail Address") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            email: true,
          },
        };
      } else if (y.name === "Name") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z]+$/,
              message: "can only contain alphabets",
            },
          },
        };
      } else if (y.name === "Last Name") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z]+$/,
              message: "can only contain alphabets",
            },
          },
        };
      } else if (y.name === "KRA Pin") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z][0-9]{9}[A-Za-z]$/,
              message: "^KRA Pin is Invalid",
            },
          },
        };
      } else if (y.name === "firstName") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[a-zA-Z][a-zA-Z '-]*$/,
              message: "can only contain alphabetic characters, hyphen and space",
            },
          },
        };
      } else if (y.name === "lastName") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[a-zA-Z][a-zA-Z '-]*$/,
              message: "can only contain alphabetic characters, hyphen and space",
            },
          },
        };
      } else if (y.name === "nationality") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z]+$/,
              message: "can only contain alphabets",
            },
          },
        };
      } else if (y.name === "occupation") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[-\sa-zA-Z0-9]+$/,
              message: "^ Only alphabetic characters, numericals, hyphen and space are allowed",
            },
          },
        };
      } else if (y.name === "email") {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
            email: true,
          },
        };
      } else {
        val = {
          ...val,
          [y.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      }
    });

    delete val["P.O. Box"];
    return val;
  };

  const executeCalculatorResponse = useSelector(
    (state) => state?.products?.productOptions
  );

  useEffect(() => {
    var arr = [...mainMemberState];
    if (!files?.length) {
      arr[0] = { ...arr[0], document_of_proof: null };
    }
    if (!carImages?.length) {
      arr[0] = { ...arr[0], carImages0: null };
    }
    setMainMember(arr);
    dispatch(
      updateCustomAttributes({
        type: "MAINMEMBER",
        payload: arr,
      })
    );
  }, []);

  const [policyExpand, setPolicyExpand] = useState(false);
  const [insuranceExpand, setInsuranceExpand] = useState(false);
  const [carExpand, setCarExpand] = useState(false);

  useEffect(() => {
    setPolicyExpand(true);
  }, []);


  const handleSubmit = async () => {
    var errors = validate(mainMemberState[0], getConstraints());
    const date = new Date()
    const todayDate = moment(date).format("YYYY-MM-DD");
    var oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    var nextYear = moment(oneYearFromNow).format("YYYY-MM-DD");

    var policyAttributes = [
      "firstName",
      "lastName",
      "email",
      "number",
      "occupation",
      "nationality",
      "nationalityId",
      "mainGender",
      "address",
      "city",
      "state",
      "Date of Birth",
      "Identification Type Number",
      "document_of_proof",
    ];

    var insuranceAttributes = [
      "Previous Insurance",
      "Disqualification Events",
      "Impeding Conditions",
    ];

    var carAttributes = ["Vehicle registration number"];
    if (selectedCoverValue === "Third Party Only") {
      carAttributes = [...carAttributes, "carImages0"];
    } else {
      carAttributes = [
        ...carAttributes,
        ...Array(8)
          .fill()
          .map((_, i) => `carImages${i}`),
      ];
    }
    if (errors || errors !== undefined) {
      if (mainMemberState?.length) {
        Object.keys(errors)?.map((x) => {
          if (policyAttributes?.includes(x)) {
            setPolicyExpand(true);
            errors = {
              ...errors,
              policy: ["Please fill main member details "],
            };
          }
        });
      }
    }

    if (errors || errors !== undefined) {
      if (mainMemberState?.length) {
        Object.keys(errors)?.map((x) => {
          if (insuranceAttributes?.includes(x)) {
            setInsuranceExpand(true);
            errors = {
              ...errors,
              insurance: ["Please fill insurance & driver's history details "],
            };
          }
        });
      }
    }

    if (errors || errors !== undefined) {
      if (mainMemberState?.length) {
        Object.keys(errors)?.map((x) => {
          if (carAttributes?.includes(x)) {
            setCarExpand(true);
            errors = {
              ...errors,
              car: ["Please fill car details "],
            };
          }
        });
      }
    }


    if (errors || errors !== undefined) {
      var errorArray = Object.keys(errors);
      document.getElementById(errorArray[0])?.scrollIntoView({
        behavior: "smooth",
      });
      return setErrors(errors);
    } else {
      setErrors({});
    }
    var policyRisks =
      selectedCoverValue === "Third Party Only"
        ? [
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("INPR"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "INPR"
              )?.instanceId,
              price:
                executeCalculatorResponse?.productOptions?.[0]?.risks?.[0]
                  ?.rating?.price,
              discount: 0.0,
              tax: executeCalculatorResponse?.productOptions?.[0]?.risks?.[0]
                ?.rating?.tax,
              total:
                executeCalculatorResponse?.productOptions?.[0]?.risks?.[0]
                  ?.rating?.total,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("DRI"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "DRI"
              )?.instanceId,
              price: 0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("PMR"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "PMR"
              )?.instanceId,
              price: 0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("UNQ"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "UNQ"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("DBD"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "DBD"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
          ]
        : [
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("INPR"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "INPR"
              )?.instanceId,
              price:
                executeCalculatorResponse?.productOptions?.[0]?.risks?.[0]
                  ?.rating?.price,
              discount: 0.0,
              tax: executeCalculatorResponse?.productOptions?.[0]?.risks?.[0]
                ?.rating?.tax,
              total:
                executeCalculatorResponse?.productOptions?.[0]?.risks?.[0]
                  ?.rating?.total,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("DRI"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "DRI"
              )?.instanceId,
              price: 0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("PMR"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "PMR"
              )?.instanceId,
              price: 0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("UNQ"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "UNQ"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            {
              externalReference: uuidv4(),
              attributes: filterRiskAttributes("DBD"),
              valuations: [],
              productRiskReference: riskAttributes?.find(
                (x) => x.code === "DBD"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
          ];
    try {
      setIsLoading(true);

      const res = await dispatch(
        CreatePolicy({
          policies: [
            {
              startDate: todayDate,
              endDate: nextYear,
              PolicyStatus: "Quoted",
              currencyCode: "TZS",
              members: [
                {
                  firstName: mainMemberState?.[0]?.firstName,
                  lastName: mainMemberState?.[0]?.lastName,
                  eMail: mainMemberState?.[0]?.email,
                  mobilePhone: mainMemberState?.[0]?.number,
                  address: mainMemberState?.[0]?.address,
                  country: mainMemberState?.[0]?.country,
                  birthDate:
                    mainMemberState?.[0][
                      getDriAttributes(ATTRIBUTES.DRI_DOB)?.name
                    ],
                  WorkGPSCoordinate: mainMemberState?.[0]?.occupation,
                  gender: mainMemberState?.[0]?.mainGender,
                  city: mainMemberState?.[0]?.city,
                  state: mainMemberState?.[0]?.state,
                  nationalityId:
                    mainMemberState?.[0]?.[`Identification Type Number`],
                  nationality: mainMemberState?.[0]?.nationality,
                  memberType: 1,
                  relationship: 0,
                },
              ],
              attributes: [],
              risks: policyRisks,
              beneficiaries: [
                {
                  firstName: mainMemberState?.[0]?.firstName,
                  lastName: mainMemberState?.[0]?.lastName,
                  eMail: mainMemberState?.[0]?.email,
                  mobilePhone: mainMemberState?.[0]?.number,
                  address: mainMemberState?.[0]?.address,
                  country: mainMemberState?.[0]?.country,
                  occupation: mainMemberState?.[0]?.occupation,
                  city: mainMemberState?.[0]?.city,
                  state: mainMemberState?.[0]?.state,
                  beneficiaryType: 0,
                  relationship: 0,
                },
              ],
              bankAccounts: null,
              productOptionReference:
                productOptionRef[0]?.productOptionReference,
              status: 15,
            },
          ],
          networkId:
            process.env.REACT_APP_ENV_TYPE === "DEV"
              ? "98c78966-239f-f0a2-16b9-d292072de3ce"
              : process.env.REACT_APP_ENV_TYPE === "PROD"
              ? "44be4f7e-6d02-cbf1-cdb5-875f1c10beec"
              : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
          companyId: agentOnBoarding?.company?.code,
          agentId: agentOnBoarding?.agent?.code,
        })
      );
      console.log("ress-->", res?.data?.data?.message);
      // Check if the response has a failure message
      if (res?.data?.data?.message) {
        setIsLoading(false);
        // Extract the message from the failureReason
        const failureMessage = extractFailureMessage(res?.data?.data?.message);
        console.log("failureMessage-->", failureMessage);
        // Set the error message and show the modal
        setErrorMessage(failureMessage);
        setShowErrorModal(true);
      }

      if (res?.data?.data?.responseBody !== null) {
        // Handle success case --- calling addPolicySaleAttachments

        let body = {
          externalReference: "CREATE_SALE_300",
          policySaleReference: res?.data?.data?.policySaleReference,
          policyAttachments: [
            {
              externalReference: "CAR_IMAGES",
              policyReference: res?.data?.data?.policies?.[0]?.policyReference,
              attachments: [
                {
                  instanceId: "100",
                  fileName: files[0].name.split(".")[0],
                  fileExtension: files[0].name.split(".")[1],
                  productAttachmentOptionInstanceId: policyDropOpt.find(
                    (att) =>
                      att.name ===
                      driverCoverAttribute?.attributes?.find(
                        (attr) => attr.name === "Identification Document Type"
                      ).value
                  )?.instanceId,
                },
                ...Array(carImages.length)
                  .fill()
                  .map((_, i) => ({
                    instanceId: (101 + i).toString(),
                    fileName: carImages[i].name.split(".")[0],
                    fileExtension: carImages[i].name.split(".")[1],
                    productAttachmentOptionInstanceId: policyAttOpt.find(
                      (att) => att.code === docArr[i]
                    ).instanceId,
                  })),
              ],
            },
          ],
        };
        let file = [...files, ...carImages];
        let tags = Array(carImages.length + 1)
          .fill()
          .map((_, i) => (100 + i).toString());
        await dispatch(policySaleAttachments({ body, file, tags }));

        const approveddata = {
          policyReference: res?.data?.data?.policies?.[0]?.policyReference,
          comment: "submitted from react front end",
        };
        // approvePolicy
        await dispatch(approvePolicy(approveddata));

        setIsLoading(true);
        //trackUserEvents
        trackUserEvents("tz_complete_your_details_next_cta", {
          firstName: mainMemberState?.[0]?.firstName,
          lastName: mainMemberState?.[0]?.lastName,
          eMail: mainMemberState?.[0]?.email,
          mobilePhone: mainMemberState?.[0]?.number,
          address: mainMemberState?.[0]?.address,
          country: mainMemberState?.[0]?.country,
          birthDate:
            mainMemberState?.[0][getDriAttributes(ATTRIBUTES.DRI_DOB)?.name],
          WorkGPSCoordinate: mainMemberState?.[0]?.occupation,
          gender: mainMemberState?.[0]?.mainGender,
          city: mainMemberState?.[0]?.city,
          state: mainMemberState?.[0]?.state,
          nationalityId: mainMemberState?.[0]?.[`Identification Type Number`],
          nationality: mainMemberState?.[0]?.nationality,
          memberType: 1,
          relationship: 0,
        });
        handleNext();
      }
      //---------[Ends] Check if the response has a failure message----[ENDS if, else block]-----//
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };


   const handleBackTrack = () => {
     //trackUserEvents
     trackUserEvents("tz_complete_your_details_back_cta", {});
     handleBack();
   };

   const extractFailureMessage = (failureReason) => {
     // Extract the message from the failureReason
     const messageIndex = failureReason.indexOf("Message: ");
     return messageIndex !== -1 ? failureReason.slice(messageIndex + 9) : null;
   };

    const closeErrorModal = () => {
      setShowErrorModal(false);
    };

  const mainMemberState = useSelector((state) => state?.products?.mainMember);

  useEffect(() => {
    var attribute = riskAttributes.find((x) => x.code === "DRI")?.attributes;
    var arr = [...attribute];
    var index = attribute.findIndex(
      (x) => x.name === "Identification Document Type"
    );
    arr[index] = { ...arr[index], value: "Drivers License" };
    dispatch(updatedAttributes(arr, "DRI"));

    var carI = {};
    [0, 1, 2, 3, 4, 5, 6, 7].forEach((index) => {
      carI = { ...carI, [`carImages${index}`]: null };
    });

    setMainMember([
      {
        ...carI,
        document_of_proof: null,
        "Identification Document Type": "Drivers License",
        ...mainMemberState[0],
      },
    ]);
  }, []);

  const [mainMember, setMainMember] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      "Identification Document Type": "Drivers License",
    },
  ]);

  const handleChangeMem = (evt, index) => {
    var arr = [...mainMemberState];
    arr[index] = { ...arr[index], [evt.target.name]: evt.target.value };
    setMainMember(arr);
    dispatch(
      updateCustomAttributes({
        type: "MAINMEMBER",
        payload: arr,
      })
    );
  };

  useEffect(() => {
    dispatch(updateAccordion(expand));
  }, [expand]);

  const policyAttachments = useSelector(
    (state) => state?.products?.policyAttachments?.attachments?.[0]
  );
  const policyAttachmentsCar = useSelector(
    (state) => state?.products?.policyAttachments?.attachments?.[1]
  );

  const [files, setFiles] = useState([]);
  const [carImages, setCarImages] = useState([]);

  const handleFileUpload = (val) => {
    if (val.target.files[0].size / 1000000 > 2) {
      setFiles([""]);
      forceUpdate();
      return toast.error("Please upload file size less than 2mb");
    }
    setFiles(val.target.files);

    dispatch(
      updateCustomAttributes({
        type: "MAINMEMBER",
        payload: [{ ...mainMemberState[0], document_of_proof: "yes" }],
      })
    );
  };

  const handleFileUploadMulti = (val, index) => {
    if (val.target.files[0].size / 1000000 > 2) {
      const arr = carImages;
      arr[index] = "";
      setCarImages(arr);
      forceUpdate();
      return toast.error("Please upload file size less than 2mb");
    }
    const arr = carImages;
    arr[index] = val?.target?.files[0];
    setCarImages(arr);
    dispatch(
      updateCustomAttributes({
        type: "MAINMEMBER",
        payload: [{ ...mainMemberState[0], [`carImages${index}`]: "yes" }],
      })
    );
  };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">
              Motor Private Insurance Cover
            </h3>
            <h5 className="primary-color f-700">Complete your details</h5>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Motor Private Insurance Cover</p>
          <h5 className="primary-color f-700">Complete your details</h5>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <h5 className="primary-color f-700 text-left">
            Please enter your personal details here:
          </h5>
        </div>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          {/* Add your detail */}
          <Accordion
            defaultExpanded={policyExpand}
            expanded={policyExpand}
            onChange={() => setPolicyExpand(!policyExpand)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="pane41a-content"
              id="panel4a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700">1 </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add your details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              {mainMemberState?.map((item, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700"> Owner's Details </p>
                  <TextInput
                    id={"firstName"}
                    label={"First name"}
                    name={"firstName"}
                    value={item.firstName}
                    onChange={(e) => {
                      handleChangeMem(e, i);
                    }}
                    required={true}
                    errors={errors["firstName"]}
                  />
                  <TextInput
                    id={"lastName"}
                    label={"Last name"}
                    name={"lastName"}
                    value={item.lastName}
                    errors={errors["lastName"]}
                    onChange={(e) => {
                      handleChangeMem(e, i);
                    }}
                    required={true}
                  />
                  <TextInput
                    id={"email"}
                    label={"Email address"}
                    name={"email"}
                    value={item.email}
                    placeholder="email@example.com"
                    errors={errors["email"]}
                    onChange={(e) => {
                      handleChangeMem(e, i);
                    }}
                    required={true}
                  />
                  <NumberInput
                    id={"number"}
                    label={"Phone number"}
                    name={"number"}
                    placeholder={"+255123456789"}
                    value={item.number}
                    errors={errors["number"]}
                    onChange={(e) => handleChangeMem(e, i)}
                    required={true}
                  />
                  <TextInput
                    id={"occupation"}
                    label={"Occupation"}
                    name={"occupation"}
                    value={item.occupation}
                    errors={errors["occupation"]}
                    onChange={(e) => {
                      handleChangeMem(e, i);
                    }}
                    required={true}
                  />
                  <TextInput
                    id={"nationality"}
                    label={"Nationality"}
                    name={"nationality"}
                    value={item.nationality}
                    errors={errors["nationality"]}
                    onChange={(e) => {
                      handleChangeMem(e, i);
                    }}
                    required={true}
                  />

                  <div className="form-group mt-3">
                    <label className="primary-color f-700">Gender*</label>
                    <ReactSelect
                      id={`mainGender`}
                      placeholder="Select Gender"
                      value={{
                        value: item?.mainGender,
                        label: item?.mainGender,
                      }}
                      options={[
                        { value: "Male", label: "Male" },
                        { value: "Female", label: "Female" },
                      ]}
                      onChange={(e) => {
                        handleChangeMem(
                          {
                            target: { name: "mainGender", value: e.value },
                          },
                          i
                        );
                      }}
                    />
                    {Object.keys(errors)?.length > 0 && (
                      <span className="text-danger errors">
                        {errors?.["mainGender"]}
                      </span>
                    )}
                  </div>

                  <TextInput
                    id={"address"}
                    label={"Address"}
                    name={"address"}
                    value={item.address}
                    errors={errors["address"]}
                    onChange={(e) => handleChangeMem(e, i)}
                    required={true}
                  />
                  <TextInput
                    id={"city"}
                    label={"City"}
                    name={"city"}
                    value={item.city}
                    errors={errors["city"]}
                    onChange={(e) => handleChangeMem(e, i)}
                    required={true}
                  />
                  <TextInput
                    id={"state"}
                    label={"District"}
                    name={"state"}
                    value={item.state}
                    errors={errors["state"]}
                    onChange={(e) => handleChangeMem(e, i)}
                    required={true}
                  />
                  {riskAttributes
                    .find((x) => x.code === "DRI")
                    ?.attributes.map((y) => {
                      if (y.dataType !== 7) {
                        return renderComponents(y);
                      } else {
                        return (
                          <Select
                            id={y.name}
                            title={y.description}
                            half={true}
                            name={y.name}
                            width={150}
                            options={policyDropOpt.map((x) => ({
                              name: x.name,
                              value: x.name,
                            }))}
                            value={y?.value}
                            defaultValue={
                              y?.value ?? JSON.parse(y.settings)?.items[0]
                            }
                            onClick={(e) =>
                              handleChange(e, y.instanceId, y.code)
                            }
                            required={y.isRequired}
                          />
                        );
                      }
                    })}
                  <FileUpload
                    title={policyAttachments?.description}
                    id="document_of_proof"
                    onChange={handleFileUpload}
                    required={true}
                    value={files[0] && files[0].length && files[0].name}
                    errors={errors["document_of_proof"]}
                    accept=".jpg,.png,.pdf,.jpeg,.bmp"
                  />
                  <div
                    style={{
                      color: "#3C4096",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          {errors && errors["policy"] && (
            <span className="errors">{errors["policy"]}</span>
          )}

          {/* Add your detail---Ends */}

          {/* UnderWriting start */}
          <div style={{ height: "20px" }} />

          <Accordion
            defaultExpanded={
              underWritingAttribute?.value === 1 ? false : insuranceExpand
            }
            expanded={insuranceExpand}
            onChange={() => setInsuranceExpand(!insuranceExpand)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700">2</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Insurance & Driver's History
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  marginTop: 20,
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <TwoButtons
                  id={unqgetAttributes(ATTRIBUTES.PREVIOUS_INSURANCE)?.name}
                  half={true}
                  containerWidth={true}
                  title={`${
                    unqgetAttributes(ATTRIBUTES.PREVIOUS_INSURANCE)?.description
                  }`}
                  state={unqgetAttributes(ATTRIBUTES.PREVIOUS_INSURANCE)?.value}
                  errors={
                    Object.keys(errors)
                      ? errors[
                          unqgetAttributes(ATTRIBUTES.PREVIOUS_INSURANCE)?.name
                        ]
                        ? errors[
                            unqgetAttributes(ATTRIBUTES.PREVIOUS_INSURANCE)
                              ?.name
                          ]
                        : [""]
                      : []
                  }
                  onClick={(e) =>
                    handleChangeQuestions(
                      e,
                      unqgetAttributes(ATTRIBUTES.PREVIOUS_INSURANCE)
                        ?.instanceId
                    )
                  }
                  options={[
                    {
                      name: "Yes",
                      value: 0,
                    },
                    {
                      name: "No",
                      value: 1,
                    },
                  ]}
                />

                <div style={{ height: "20px" }} />
                <TwoButtons
                  id={
                    unqgetAttributes(ATTRIBUTES.DISQUALIFICATION_EVENTS)?.name
                  }
                  half={true}
                  containerWidth={true}
                  title={`${
                    unqgetAttributes(ATTRIBUTES.DISQUALIFICATION_EVENTS)
                      ?.description
                  }`}
                  state={
                    unqgetAttributes(ATTRIBUTES.DISQUALIFICATION_EVENTS)?.value
                  }
                  errors={
                    Object.keys(errors)
                      ? errors[
                          unqgetAttributes(ATTRIBUTES.DISQUALIFICATION_EVENTS)
                            ?.name
                        ]
                        ? errors[
                            unqgetAttributes(ATTRIBUTES.DISQUALIFICATION_EVENTS)
                              ?.name
                          ]
                        : [""]
                      : []
                  }
                  onClick={(e) =>
                    handleChangeQuestions(
                      e,
                      unqgetAttributes(ATTRIBUTES.DISQUALIFICATION_EVENTS)
                        ?.instanceId
                    )
                  }
                  options={[
                    {
                      name: "Yes",
                      value: 0,
                    },
                    {
                      name: "No",
                      value: 1,
                    },
                  ]}
                />

                <div style={{ height: "20px" }} />
                <TwoButtons
                  id={unqgetAttributes(ATTRIBUTES.IMPENDING_CONDITIONS)?.name}
                  half={true}
                  containerWidth={true}
                  title={`${
                    unqgetAttributes(ATTRIBUTES.IMPENDING_CONDITIONS)
                      ?.description
                  }`}
                  state={
                    unqgetAttributes(ATTRIBUTES.IMPENDING_CONDITIONS)?.value
                  }
                  errors={
                    Object.keys(errors)
                      ? errors[
                          unqgetAttributes(ATTRIBUTES.IMPENDING_CONDITIONS)
                            ?.name
                        ]
                        ? errors[
                            unqgetAttributes(ATTRIBUTES.IMPENDING_CONDITIONS)
                              ?.name
                          ]
                        : [""]
                      : []
                  }
                  onClick={(e) =>
                    handleChangeQuestions(
                      e,
                      unqgetAttributes(ATTRIBUTES.IMPENDING_CONDITIONS)
                        ?.instanceId
                    )
                  }
                  options={[
                    {
                      name: "Yes",
                      value: 0,
                    },
                    {
                      name: "No",
                      value: 1,
                    },
                  ]}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {errors && errors["insurance"] && (
            <span className="errors">{errors["insurance"]}</span>
          )}

          {/* Underwriting Ends */}

          <div style={{ height: "20px" }} />
          {/* Car details */}
          <Accordion
            defaultExpanded={
              selectedCoverValue === "Third Party Only" ? false : carExpand
            }
            expanded={carExpand}
            onChange={() => setCarExpand(!carExpand)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700">3</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Car Details
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  marginTop: 20,
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                {riskAttributes
                  .find((x) => x.code === "PMR")
                  ?.attributes.map((y) =>
                    renderComponents({ ...y, placeholder: "T123DUJ" })
                  )}
                {/* <div className="primary-color f-700 mt-2">
                  {policyAttachmentsCar?.description}
                </div> */}
                <FileUpload
                  title={"Vehicle Registration Card*"}
                  onChange={(e) => handleFileUploadMulti(e, 0)}
                  value={
                    carImages[0] && carImages[0].length && carImages[0].name
                  }
                  errors={errors[`carImages0`]}
                  accept=".jpg,.png,.pdf,.jpeg,.bmp"
                />
                <div
                  style={{
                    color: "#3C4096",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                </div>
                {selectedCoverValue !== "Third Party Only" && (
                  <>
                    <FileUpload
                      title={"Front View"}
                      onChange={(e) => handleFileUploadMulti(e, 1)}
                      errors={errors[`carImages1`]}
                      value={
                        carImages[1] && carImages[1].length && carImages[1].name
                      }
                      accept=".jpg,.png,.pdf,.jpeg,.bmp"
                    />
                    <div
                      style={{
                        color: "#3C4096",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                    </div>

                    <FileUpload
                      title={"Rear View"}
                      onChange={(e) => handleFileUploadMulti(e, 2)}
                      errors={errors[`carImages2`]}
                      value={
                        carImages[2] && carImages[2].length && carImages[2].name
                      }
                      accept=".jpg,.png,.pdf,.jpeg,.bmp"
                    />
                    <div
                      style={{
                        color: "#3C4096",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                    </div>

                    <FileUpload
                      title={"Left Side View"}
                      onChange={(e) => handleFileUploadMulti(e, 3)}
                      errors={errors[`carImages3`]}
                      value={
                        carImages[3] && carImages[3].length && carImages[3].name
                      }
                      accept=".jpg,.png,.pdf,.jpeg,.bmp"
                    />
                    <div
                      style={{
                        color: "#3C4096",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                    </div>

                    <FileUpload
                      title={"Right Side View"}
                      onChange={(e) => handleFileUploadMulti(e, 4)}
                      value={
                        carImages[4] && carImages[4].length && carImages[4].name
                      }
                      errors={errors[`carImages4`]}
                      accept=".jpg,.png,.pdf,.jpeg,.bmp"
                    />
                    <div
                      style={{
                        color: "#3C4096",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                    </div>

                    <FileUpload
                      title={"Open Bonnet"}
                      onChange={(e) => handleFileUploadMulti(e, 5)}
                      value={
                        carImages[5] && carImages[5].length && carImages[5].name
                      }
                      errors={errors[`carImages5`]}
                      accept=".jpg,.png,.pdf,.jpeg,.bmp"
                    />
                    <div
                      style={{
                        color: "#3C4096",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                    </div>

                    <FileUpload
                      title={"Dashboard"}
                      onChange={(e) => handleFileUploadMulti(e, 6)}
                      value={
                        carImages[6] && carImages[6].length && carImages[6].name
                      }
                      errors={errors[`carImages6`]}
                      accept=".jpg,.png,.pdf,.jpeg,.bmp"
                    />
                    <div
                      style={{
                        color: "#3C4096",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                    </div>

                    <FileUpload
                      title={"Chassis Number"}
                      onChange={(e) => handleFileUploadMulti(e, 7)}
                      value={
                        carImages[7] && carImages[7].length && carImages[7].name
                      }
                      errors={errors[`carImages7`]}
                      accept=".jpg,.png,.pdf,.jpeg,.bmp"
                    />
                    <div
                      style={{
                        color: "#3C4096",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                    </div>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["car"] && (
            <span className="errors">{errors["car"]}</span>
          )}

          {/* Car Details Ends */}
          {/* ----------------------Error modal ---------------------------------------*/}
          {showErrorModal && (
            // <div className="modal-overlay">
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                zIndex: "9999",
              }}
            >
              {console.log("showErrorModal-->", showErrorModal, errorMessage)}
              {/* <div className="modal"> */}
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                  maxWidth: "80%",
                }}
              >
                <p style={{ marginBottom: "20px" }}>{errorMessage}</p>
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#E33252",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={closeErrorModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">
            Total Annual premium (VAT Inclusive){" "}
          </h5>
          <h5 className="text-white f-700">{`TSh ${productOptions
            .map((ele, index) => ele.risks[0] && ele.risks[0].rating.total)[0]
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h5>
        </div>
      </div>

      <NavigationButtons
        isBackDisabled={isLoading}
        back={handleBackTrack}
        loading={isLoading}
        next={handleSubmit}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

export default Step2;
