/* eslint-disable */

// <----- API URL  ------->
export const APIUrl = {
  login: "/lg/authorisation/login",
};

// <----- Environment types  ------->
export const ENV_TYPE = {
  DEV: "dev",
  PROD: "prod",
  UAT: "uat",
};

// <----- Environments  ------->
export const ENV = {
  ["dev"]: "https://briisk-i-dev-api.azure-api.net",
  ["uat"]: "https://briisk-i-uat-api.azure-api.net",
  ["prod"]: "https://briisk-i-prod-api.azure-api.net",
};

export const OCP_APIM_SUBSCRIPTION_KEY = {
  ["dev"]: "a00a7ff5c53f4c628979941833d63dc9",
  ["uat"]: "3296a582dbb540b8ae4843332c638a3e",
  ["prod"]: "d6cd16e3f619492bbc3f75fccf0aba6c",
};

export const PRODUCT_INSTANCE_ID = {
  ["dev"]: "abc8840c-a181-4f34-baf6-955f10815c7e",
  ["uat"]: "42174780-44cf-4217-a1c6-705c470fefd5", // Same as for DEV
  ["prod"]: "525d6430-31e2-4d4b-8943-f436402b8c65",
};

export const BANK_BRANCH_GUID = {
  ["dev"]: "F5D22457-30FC-8FC4-90DB-BC43DC258464",
  ["uat"]: "201413A3-4410-B6C1-498E-7FD5D7100874",
  ["prod"]: "7b9d8f0d-62fe-cf02-d6cf-0e5dafccef01",
};

export const MIDDLEWARE_URL = {
  // ["dev"]: "http://localhost:5000",
  ["dev"]: "https://react-middleware-dev.azurewebsites.net",
  ["uat"]: "https://react-middleware-uat.azurewebsites.net",
  ["prod"]: "https://react-middleware-prod.azurewebsites.net",
  // ["prod"]: "http://localhost:8000",

};

/* eslint-disable */
