import {BANK_BRANCH_GUID, ENV, ENV_TYPE,  MIDDLEWARE_URL, OCP_APIM_SUBSCRIPTION_KEY,  PRODUCT_INSTANCE_ID} from './api-constants';

// <-------- Constants ------->

// Define the ENV TYPE in ENV_VAR
const ENV_VAR = ENV_TYPE[process.env.REACT_APP_ENV_TYPE];
const CONSTANTS = {
  BASEURL: ENV[ENV_VAR],
  ENV: ENV_VAR,
  OCP_APIM_SUBSCRIPTION_KEY: OCP_APIM_SUBSCRIPTION_KEY[ENV_VAR],
  PRODUCT_INSTANCE_ID: PRODUCT_INSTANCE_ID[ENV_VAR],
  BANK_BRANCH_GUID: BANK_BRANCH_GUID[ENV_VAR],
  MIDDLEWARE_URL: MIDDLEWARE_URL[ENV_VAR],
  CURRENCY_CODE: 'ZAR'
};
export default CONSTANTS;

export const PRODUCTION_CONSTANTS = {
  REFERER_BASE_URL_PROD: 'https://policy-cache-itp2-prod-za.azurewebsites.net',
  COMPANY_DETAILS: 'https://organisation-itp2-prod-za.azurewebsites.net',
  PRODUCT_RISKS: 'https://product-itp2-prod-za.azurewebsites.net',
  BANK_LOOKUPS: 'https://maintaindata-itp2-prod-za.azurewebsites.net',
  POLICY: 'https://policy-itp2-prod-za.azurewebsites.net'
}


// <-------- Common Messages ------->
export const COMMONMESSAGES = {};


export const ATTRIBUTES = {
  LEVEL_OF_COVER: "Level of Cover",
  CLAIMS_HISTORY: "Claims History",
  EXCESS_BUY_BACK: "Excess Buy-Back",
  LOSS_OF_USE: "Loss of Use",
  TRACKING_DEVICE: "Tracking Device",
  PREVIOUS_INSURANCE: "Previous Insurance",
  DISQUALIFICATION_EVENTS: "Disqualification Events",
  IMPENDING_CONDITIONS: "Impeding Conditions",
  DRI_DOB: "Date of Birth",
  VEHICLE_VALUE: "Vehicle Value",
};

export const RISKS = {
  DBD: 'DBD',
  DRI: 'DRI',
  INPR: 'INPR',
  UNQ: 'UNQ',
}

export const CONFIG = {
  development: "http://localhost:3001",
  uat: "https://app.uat.letshego.com",
  production: "https://app.uat.letshego.com",
  // production: "https://app.production.com",
};