import React, { useEffect, useState } from "react";
import classNames from "classnames";

// <------- MUI Components  ---------->
import { Divider } from "@mui/material";

// <------ React-bootstrap Components ------->
import { Col, Row } from "react-bootstrap";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";
import NavigationButtons from "../../../components/NavigationButtons";
import { useNavigate } from "react-router";
import style from "../../../components/components.module.css";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import { trackUserEvents } from "../../../utils/utils";

const GetQuote = ({ handleNext, handleBack }) => {
  const { width } = useWindowDimensions();

  // Selectors from Store
  const productOptions = useSelector(
    (state) => state.products?.productOptions?.productOptions
  );
  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "INPR")?.attributes
  );
  useEffect(() => {
    if (riskAttributes?.length) {
      setAttributes(riskAttributes.map((d) => ({ ...d, value: d.value })));
    }
  }, [riskAttributes]);

  const [attributes, setAttributes] = useState([]);
  const InsuranceType = attributes.find((x) =>
    ATTRIBUTES.LEVEL_OF_COVER.includes(x.name)
  )?.value;
  const Excess = attributes.find((x) =>
    ATTRIBUTES.EXCESS_BUY_BACK.includes(x.name)
  )?.value;

  // Redux Hooks
  const dispatch = useDispatch();

  // Dispatching selected products
  
  const handleSubmit = () => {
    //trackUserEvents
    trackUserEvents("tz_motor_insurance_policy_application_cover_cta", {
      "Total Annual Payment": `TSh ${productOptions
        .map((ele, index) => ele.risks[0] && ele.risks[0].rating.total)[0]
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    });
    handleNext();
  };

    const handleBackTrack = () => {
      //trackUserEvents
      trackUserEvents("tz_motor_insurance_policy_application_cover_back_cta", {});
      handleBack();
    };

  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">
                    Motor Private Insurance Cover
                  </h3>
                  <h5 className="primary-color f-700">Policy Application</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="primary-color f-500 m-0 ">
                  Motor Private Insurance Cover
                </p>
                <h5 className="primary-color f-700">Policy Application</h5>
              </div>
            )}

            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <div className={style.summary_container}>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <h3 className="primary-color f-700 ">Motor</h3>
                    <h3 className="primary-color f-700 ">Insurance</h3>
                  </Col>
                </Row>

                <Row className="justify-content-between mt-4">
                  <p className="primary-color f-700 text-left">
                    Total Annual Payment(VAT Inclusive) :
                  </p>
                  <p
                    className="f-700 text-left w-100"
                    style={{
                      color: "#709C35",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >{`TSh ${productOptions
                    .map(
                      (ele, index) => ele.risks[0] && ele.risks[0].rating.total
                    )[0]
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
                </Row>
                <Divider />

                <Row className="justify-content-between mt-4">
                  <p
                    className="f-700 text-left"
                    style={{ color: "#5A727A", fontSize: "15px" }}
                  >
                    Insurance type :
                  </p>
                  <p
                    className="f-700 text-left w-100"
                    style={{ color: "#709C35", fontSize: "15px" }}
                  >
                    {InsuranceType}
                  </p>
                </Row>

                <Row className="justify-content-between mt-4">
                  <p
                    className="f-700 text-left"
                    style={{ color: "#5A727A", fontSize: "15px" }}
                  >
                    Excess :
                  </p>
                  <p
                    className="f-700 text-left w-100"
                    style={{ color: "#709C35", fontSize: "15px" }}
                  >
                    {
                      productOptions?.[0]?.risks?.[0]?.rating?.message.split(
                        ":"
                      )[1]
                    }
                  </p>
                </Row>

                <Divider />

                <Row className="justify-content-between mt-3">
                  <p className="primary-color f-300  w-100">
                    This is private vehicle insurance and does not cover you car
                    for business use
                  </p>
                </Row>
              </div>
              <button
                type="submit"
                className="button-yellow mt-4"
                onClick={handleSubmit}
              >
                Apply for Cover
              </button>
            </div>
          </div>
        </div>
      </div>
      <NavigationButtons
        back={handleBackTrack}
        next={handleSubmit}
        disabled={true}
      />
    </div>
  );
};

export default GetQuote;
 