import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { TwoButtons, Select, TextInput } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import style from "../Form.module.css";
import { ATTRIBUTES,} from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  updatedAttributes,
  executeCalculator,
} from "../../../store/actions/products";
import { useNavigate } from "react-router";
import { validate } from "validate.js";
import { trackUserEvents } from "../../../utils/utils";

const Step1 = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const state = useSelector((state) => state.products.riskAttributes);
  const history = useNavigate();

  const [loader, setLoader] = useState(true);

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "INPR")?.attributes
  );

  const productTypes = useSelector((state) => state.products?.types);
  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };

  const [attributes, setAttributes] = useState([]);
  const [coverLevel, setCoverLevel] = useState([]);

  //Constraints for Validation
  const [constraints, setConstraints] = useState([]);
  const [errorValidation, setErrorValidation] = useState({});
  useEffect(() => {
    var constraints = {};
    if (riskAttributes?.length) {
      riskAttributes.forEach((item, index) => {
        if (item.name === "Vehicle Value") {
          constraints = {
            ...constraints,
            [item.name]: {
              presence: {
                allowEmpty: false,
              }, 
            }, 
          };
        } 
      });
    }
    setConstraints(constraints);
  }, [riskAttributes]);


  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (
      riskAttributes &&
      riskAttributes.length &&
      !riskAttributes?.[0]?.value
    ) {
      const value = JSON.parse(riskAttributes?.[0]?.settings).items[0];
      handleChange(value, riskAttributes?.[0]?.instanceId);
    }
    if (riskAttributes?.[0]?.value === "Comprehensive") {
      var constraints ={}
      if( riskAttributes){
      riskAttributes?.slice(1,6)?.map((atb) => constraints = {
        ...constraints,
        [atb.name]: {
          presence: {
            allowEmpty: false,
          }, 
        },
        ['Vehicle Value']: {
          presence: {
            allowEmpty: false,
          }, 
          format: {
            pattern: /^[0-9,]+$/,
            message: "can only contain numbers",
          },
        },
      })
    }
      setConstraints(constraints)
      setCoverLevel({
        target: { value: "Comprehensive", name: "Level of Cover" },
      });
    } 
  }, [riskAttributes]);

  useEffect(() => {
    if (riskAttributes?.length) {
      setAttributes(riskAttributes.map((d) => ({ ...d, value: d.value })));
    }
  }, [riskAttributes]);

  const handleChange = (evt, instanceId) => {
    var arr = [...riskAttributes];
    var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "INPR"));
  };

  const handleSubmit = async () => {
    try {
      var values = {};
      riskAttributes.forEach((item, index) => {
        values = {
          ...values,
          [item.name]: item.value,
        };
      });
      var errors = validate(values, constraints);
      if (errors || errors !== undefined) {
        return setErrorValidation(errors);
      }
      setIsLoading(true);
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference: productTypes?.types?.[0]?.instanceId,
            risks: [
              {
                attributes: riskAttributes?.map(
                  ({ name, dataType, instanceId, value }) => ({
                    name,
                    dataType,
                    instanceId,
                    value:
                      riskAttributes.find((r) => r.name === "Level of Cover")
                        .value === "Comprehensive" ||
                      name === "Level of Cover" ||
                      name === "Vehicle Value"
                        ? value
                        : null,
                  })
                ),
                riskReference: state?.[0]?.instanceId,
                code: "INPR",
              },
            ],
          },
        })
      );
      //trackUserEvents
      trackUserEvents("tz_motor_insurance_select_your_cover_cta", {
        "Level of Cover": getAttributes(ATTRIBUTES.LEVEL_OF_COVER)?.value,
        "Vehicle Value": getAttributes(ATTRIBUTES.VEHICLE_VALUE)?.value,
        "Claims History": getAttributes(ATTRIBUTES.CLAIMS_HISTORY)?.value,
        "Excess Buy-Back": getAttributes(ATTRIBUTES.EXCESS_BUY_BACK)?.value,
        "Loss of Use": getAttributes(ATTRIBUTES.LOSS_OF_USE)?.value,
        "Tracking Device": getAttributes(ATTRIBUTES.TRACKING_DEVICE)?.value,
      });
      setIsLoading(false);
      handleNext();
    } catch (errors) {
      console.log("Error", errors);
      setIsLoading(false);
    }
  };

   const handleBackTrack = () => {
     history("/");
     //trackUserEvents
     trackUserEvents("tz_motor_insurance_select_your_cover_back_cta", {});
   };

  const handleBlur = (evt, instanceId) => {
    if(evt.target.name === 'Vehicle Value'){
      var arr = [...riskAttributes];
      var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
      arr[index] = { ...arr[index], value: `${ isNaN(parseInt(evt.target.value)) ? "" : parseInt(evt?.target?.value).toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`};
      dispatch(updatedAttributes(arr, "INPR"));
    }
  }
  const handleFocus = (evt, instanceId) => {
    if(evt.target.name === 'Vehicle Value'){
      var arr = [...riskAttributes];
      var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
      arr[index] = { ...arr[index], value: isNaN(parseInt(evt?.target?.value.toString()
        .replaceAll( ",",""))) ? "" : parseInt(evt?.target?.value.toString()
        .replaceAll( ",",""))}; 
      dispatch(updatedAttributes(arr, "INPR"));
    }
  }

  const renderComponent = useCallback(
    (item, i) => {
      switch (item.dataType) {
        case 7:
          if (
            item.settings.includes('["YES","NO"]') ||
            item.settings.includes('["NO","YES"]')
          ) {
            return <></>;
          }
          return (
            <Select
              id={item.name}
              title={item.description}
              errors={errorValidation[item.name]}
              half={true}
              name={item.name}
              width={150}
              isMobile={width < 700}
              options={JSON.parse(item.settings)?.items?.map((x) => ({
                name: x,
                value: x,
              }))}
              value={item?.value}
              defaultValue={item?.value ?? 0}
              onClick={(e) => {
                handleChange(e, item.instanceId);
                setCoverLevel({ target: { value: e, name: item.name } });
              }}
              required={item.isRequired}
            />
          );

        case 3:
          return (
            <TextInput
              required={item.isRequired}
              errors={errorValidation[item.name]}
              placeholder={item.description}
              label={item.name}
              name={item.name}
              value={item?.value}
              onBlurCapture={(e) => handleBlur(e, item.instanceId)}
              handleNBlur
              onChange={(e) => handleChange(isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value) === 0 ? "" : parseInt(e.target.value), item.instanceId)}
            />
          );

        default:
          return;
      }
    },
    [attributes, riskAttributes, errorValidation]
  );

  return (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">
              Motor Private Insurance Cover
            </h3>
            <h2 className="primary-color f-800">Select your cover</h2>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#00000020",
              }}
            ></div>
          </div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Motor Private Insurance Cover</p>
          <h2 className="primary-color f-800">Select your cover</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        {width > 770 ? (
          <>
            <div
              style={{
                width: "460px",
                height: "auto",
                border: "1px solid #00000030",
                padding: "10px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              {attributes
                ?.filter(
                  (att) =>
                    !(
                      att.settings.includes('["YES","NO"]') ||
                      att.settings.includes('["NO","YES"]')
                    )
                )
                ?.map((d, i) => (
                  <Row key={i} className="p-1">
                    {renderComponent(d, i)}
                  </Row>
                ))}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                width: "90%",
                height: "auto",
                border: "1px solid #00000030",
                padding: "10px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              {attributes
                ?.filter(
                  (att) =>
                    !(
                      att.settings.includes('["YES","NO"]') ||
                      att.settings.includes('["NO","YES"]')
                    )
                )
                ?.map((d, i) => (
                  <Row key={i} className="p-1">
                    {renderComponent(d, i)}
                  </Row>
                ))}
            </div>
          </>
        )}
        <div style={{ height: "20px" }} />
        {coverLevel?.target?.value === "Comprehensive" ? (
          <>
            <TwoButtons
              id={getAttributes(ATTRIBUTES.CLAIMS_HISTORY)?.name}
              half={true}
              title={`${getAttributes(ATTRIBUTES.CLAIMS_HISTORY)?.description}`}
              state={getAttributes(ATTRIBUTES.CLAIMS_HISTORY)?.value}
              errors={
                Object.keys(errorValidation)
                  ? errorValidation[
                      getAttributes(ATTRIBUTES.CLAIMS_HISTORY)?.name
                    ]
                    ? errorValidation[
                        getAttributes(ATTRIBUTES.CLAIMS_HISTORY)?.name
                      ]
                    : [""]
                  : []
              }
              onClick={(e) =>
                handleChange(
                  e,
                  getAttributes(ATTRIBUTES.CLAIMS_HISTORY)?.instanceId
                )
              }
              options={[
                {
                  name: "Yes",
                  value: "YES",
                },
                {
                  name: "No",
                  value: "NO",
                },
              ]}
            />
            <div style={{ height: "20px" }} />
            <TwoButtons
              id={getAttributes(ATTRIBUTES.EXCESS_BUY_BACK)?.name}
              half={true}
              title={`${
                getAttributes(ATTRIBUTES.EXCESS_BUY_BACK)?.description
              }`}
              state={getAttributes(ATTRIBUTES.EXCESS_BUY_BACK)?.value}
              errors={
                Object.keys(errorValidation)
                  ? errorValidation[
                      getAttributes(ATTRIBUTES.EXCESS_BUY_BACK)?.name
                    ]
                    ? errorValidation[
                        getAttributes(ATTRIBUTES.EXCESS_BUY_BACK)?.name
                      ]
                    : [""]
                  : []
              }
              onClick={(e) =>
                handleChange(
                  e,
                  getAttributes(ATTRIBUTES.EXCESS_BUY_BACK)?.instanceId
                )
              }
              options={[
                {
                  name: "Yes",
                  value: "YES",
                },
                {
                  name: "No",
                  value: "NO",
                },
              ]}
            />
            <div style={{ height: "20px" }} />
            <TwoButtons
              id={getAttributes(ATTRIBUTES.LOSS_OF_USE)?.name}
              half={true}
              title={`${getAttributes(ATTRIBUTES.LOSS_OF_USE)?.description}`}
              state={getAttributes(ATTRIBUTES.LOSS_OF_USE)?.value}
              errors={
                Object.keys(errorValidation)
                  ? errorValidation[getAttributes(ATTRIBUTES.LOSS_OF_USE)?.name]
                    ? errorValidation[
                        getAttributes(ATTRIBUTES.LOSS_OF_USE)?.name
                      ]
                    : [""]
                  : []
              }
              onClick={(e) =>
                handleChange(
                  e,
                  getAttributes(ATTRIBUTES.LOSS_OF_USE)?.instanceId
                )
              }
              options={[
                {
                  name: "Yes",
                  value: "YES",
                },
                {
                  name: "No",
                  value: "NO",
                },
              ]}
            />
            <div style={{ height: "20px" }} />
            <TwoButtons
              id={getAttributes(ATTRIBUTES.TRACKING_DEVICE)?.name}
              half={true}
              title={`${
                getAttributes(ATTRIBUTES.TRACKING_DEVICE)?.description
              }`}
              state={getAttributes(ATTRIBUTES.TRACKING_DEVICE)?.value}
              errors={
                Object.keys(errorValidation)
                  ? errorValidation[
                      getAttributes(ATTRIBUTES.TRACKING_DEVICE)?.name
                    ]
                    ? errorValidation[
                        getAttributes(ATTRIBUTES.TRACKING_DEVICE)?.name
                      ]
                    : [""]
                  : []
              }
              onClick={(e) =>
                handleChange(
                  e,
                  getAttributes(ATTRIBUTES.TRACKING_DEVICE)?.instanceId
                )
              }
              options={[
                {
                  name: "Yes",
                  value: "YES",
                },
                {
                  name: "No",
                  value: "NO",
                },
              ]}
            />{" "}
          </>
        ) : (
          <></>
        )}
      </div>

      <NavigationButtons
        isBackDisabled={isLoading}
        back={handleBackTrack}
        loading={isLoading}
        next={handleSubmit}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

export default Step1;
