import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Summary from "../../../components/Forms/Summary";
import { RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import {sanlam} from '../../../assets/images/index';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  document.body.style = "background: #3C409610;";
  const policy = useSelector((state) => state?.prevState?.policy);
  const mainMember = useSelector((state) => state?.prevState.mainMember);
  const premiumAmount = useSelector(
    (state) => state?.prevState?.productOptions
  );

  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            <h3 className={style.header}>
              Instant Motor Cover in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">
                    Motor Private Insurance Cover
                  </h3>
                  <h5 className="primary-color f-700">Policy Application</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">
                  Motor Private Insurance Cover
                </p>
                <h5 className="primary-color f-700">Policy Application</h5>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <Summary
                title="Motor Private Insurance Cover"
                referenceNumber={
                  policy?.policies && policy?.policies[0]?.policyReference
                }
                policyHolder={
                  mainMember && mainMember?.[0].firstName
                    ? mainMember?.[0]?.firstName
                    : "NA"
                }
                policyHolderSurname={
                  mainMember && mainMember?.[0].lastName
                    ? mainMember[0].lastName
                    : "NA"
                }
                premiumAmount={
                  premiumAmount?.productOptions?.map(
                    (ele, index) => ele.risks[0] && ele?.risks[0]?.rating?.total
                  )[0]
                }
              />
              {/* <div className="w-full mb-4 text-center">
            <button
              type="submit"
              className="button-yellow mt-4 m-auto"
              onClick={() => navigate("/")}
            >
              Go To Home
            </button>
          </div> */}
          <div style={{maxWidth: '460px' ,width: '100%' ,marginTop:"5px"}} className="d-flex justify-content-center flex-column">
                <img src={sanlam} style={{height: '30px'}} />
                <p style={{fontSize: 13, textAlign: 'center', marginTop: 5}}>
                Underwritten by Sanlam Insurance Company Limited (Reg. No. 0000/000000/00), a Licensed Life Insurer and an authorised Financial Services Provider
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
