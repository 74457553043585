// initial state of the reducer
const initialState = {
  risks: {},
  riskAttributes: [],
  types: {},
  selectedOption: null,
  companyDetails: {
    workCompanyOptions: "Company",
  },
  activeStep: 0,
  productOptionsNext: [],
  bankDetails: [],
  presistedRiskAttributes: [],
  tryCount: 0,
  isLoading: false,
  childAttributes: [],
  parentAttributes: [],
  driverAttributes: [],
  protectionIndividual: [],
  protectionFamily: [],
  premium: null,
  terms: false,
  policy: null,
  beneficiaries: [
    {
      firstName: "",
      lastName: "",
      email: "",
      number: null,
      percentage: "",
    },
  ],
  mainMember: [
    {
      firstName: "",
      lastName: "",
      email: "",
      number: null,
      "Identification Document Type": "Drivers License",
    },
  ],
  accordion: {
    policy: true,
  },
  policyAttachments: {},
  policyAttachmentsOptions: [],
  policyDropdownOptions:[],
  countDown: {count:0},
};

// function to update state
function products(state = initialState, action) {
  // destructuring the action from function
  const { type, payload } = action;

  // switch to match type and update payload
  switch (type) {
    case "PRODUCT_RISKS":
      return {
        ...state,
        risks: payload,
      };

    case "ACCORDION": {
      return {
        ...state,
        accordion: {
          ...state.accordion,
          ...payload,
        },
      };
    }

    case "POLICY_ATTACHMENT_OPTIONS":
      return {
        ...state,
        policyAttachmentsOptions: [
          ...state.policyAttachmentsOptions,
          ...payload,
        ],
      };
      case "POLICY_DROPDOWN":
        return {
          ...state,
          policyDropdownOptions: [
            ...state.policyDropdownOptions,
            ...payload,
          ],
        };  

    case "POLICY_ATTACHMENT":
      return {
        ...state,
        policyAttachments: payload,
      };

    case "TERMS":
      return {
        ...state,
        terms: payload,
      };

    case "CLEAR_RISK_STATE": {
      return {
        riskAttributes: payload,
        presistedRiskAttributes: payload,
        protectionIndividual: state.protectionIndividual,
        protectionFamily: state.protectionFamily,
        premium: null,
        terms: false,
        policy: null,
        beneficiaries: [
          {
            firstName: "",
            lastName: "",
            email: "",
            number: null,
            percentage: "",
          },
        ],
        mainMember: [
          {
            firstName: "",
            lastName: "",
            email: "",
            number: null,
          },
        ],
        accordion: {
          policy: true,
        },
        childAttributes: [],
        parentAttributes: [],
        driverAttributes: [],
        activeStep: 0,
        types: {},
      };
    }

    case "NUMBER_OF_PARENTS":
      return {
        ...state,
        numberOfParents: payload,
      };

    case "BENEFICIARIES":
      return {
        ...state,
        beneficiaries: payload,
      };

    case "MAINMEMBER":
      return {
        ...state,
        mainMember: payload,
      };
    case "UPDATE_ATTRIBUTES":
      return {
        ...state,
        riskAttributes: payload,
      };

    case "PROTECTION_INDIVIDUAL":
      return {
        ...state,
        protectionIndividual: payload,
      };

    case "PREMIUM":
      return {
        ...state,
        premium: payload,
      };

    case "PROTECTION_FAMILY":
      return {
        ...state,
        protectionFamily: payload,
      };
    case "CHILD_ATTRIBUTES":
      return {
        ...state,
        childAttributes: payload,
      };

    case "PARENT_ATTRIBUTES":
      return {
        ...state,
        parentAttributes: payload,
      };

    case "DRIVER_ATTRIBUTES":
      return {
        ...state,
        driverAttributes: payload,
      };
    case "UPDATE_T&C":
      return {
        ...state,
        tCChecked: payload,
      };

    case "PRODUCT_TYPES":
      return {
        ...state,
        types: {
          ...state.types,
          ...payload,
        },
      };

    case "STORE_BUB_REQUESTED_BODY":
      return {
        ...state,
        requestedPayload: payload,
      };

    case "POLICY":
      return {
        ...state,
        policy: payload,
      };

    case "PRODUCT_RISKS_ATTRIBUTES":
      return {
        ...state,
        riskAttributes: [...state.riskAttributes, action.payload],
      };

    case "PRESISTED_PRODUCT_RISKS_ATTRIBUTES":
      return {
        ...state,
        presistedRiskAttributes: [
          ...state.presistedRiskAttributes,
          action.payload,
        ],
      };
    case "PRODUCT_OPTIONS":
      return {
        ...state,
        productOptions: payload,
        productOptionsNext: action.payload1,
      };

    case "UPDATE_ATTRIBUTE_1":
      return {
        ...state,
        riskAttributes: payload,
      };

    case "SELECT_PRODUCT":
      return {
        ...state,
        selectedProduct: payload,
      };
    case "SELECTED_OPTION":
      return {
        ...state,
        selectedOption: payload,
      };

    case "UPDATE_COMPANY_DETAILS":
      return {
        ...state,
        companyDetails: payload,
      };

    case "CREATE_POLICY":
      return {
        ...state,
        policyResponse: payload,
      };

    case "LOADING":
      return {
        ...state,
        isLoading: payload,
      };
      
      case 'COUNTDOWN': {
        return {
          ...state,
          countDown: {...state.countDown,count:payload}
        }
      }  

    case "UPDATE_STEP":
      return {
        ...state,
        activeStep: payload,
      };

    case "BANK_OPTIONS":
      return {
        ...state,
        bankDetails: payload,
      };

    case "UPDATE_TRY_COUNT":
      return {
        ...state,
        tryCount: payload,
      };

    case "CLEAR_STATE":
      return initialState;

    // return default state if the type doesnt match any case
    default:
      return state;
  }
}
export default products;
