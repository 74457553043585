 // default imports for react project
import React, { useState, useEffect }from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import images from assets folder

//   import custom component
import BodyButton from "../Buttons/BodyButton";
import { box, sanlam, Car } from "../../assets/images";
import { Divider } from "@mui/material";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  clearState,
} from "../../store/actions/products";
import {
  getAgentOnboarding,
  login,
} from "../../store/actions/auth";
import {trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Card2({ width }) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);
  const [isLoading, setIsLoading] = useState(false);
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;
  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;
 
  const handleSubmit = async () => {
    try{
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductTypeDetails());
      riskRes?.risks?.forEach(async (risk, index) => {
        await dispatch(getProductRisksAttributes(risk.instanceId));
        if (riskRes?.risks?.length - 1 === index) {
          history("/forms");
          setIsLoading(false);
        }
      });
      setIsCounter(false);
      //trackUserEvents
      trackUserEvents("tz_motor_insurnace_get_a_quote_cta", {});
      return;
    }catch(err){
      console.log(err);
    }
  }

  return (
    <div>
    <Col lg xs={12}>
      <Row className="m-0 pb-5 card__homepage">
        <div
          style={{
            backgroundColor: "#E5E5F5",
            padding: width < 430 ? "0px" : "",
          }}
        >
          <Row className="m-4">
            <Col lg={10}>
              <h3 style={{ fontSize: "26pt", color: "#2F2E80" }}>
                It's never been easier to get insurance cover.{" "}
              </h3>
              <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>
                Cover your vehicle for all life's incidents, especially when it
                is not your fault.
              </h6>
            </Col>
            <Col lg={2}>
              <Row>
              <h6
                  style={{
                    color: "#56616C",
                    fontSize: "8pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginTop:"5px"
                  }}
                >Policies underwritten by</h6>
                </Row>
                <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={sanlam}
                    style={{
                      marginBottom: 20,
                      width: "95px",
                      marginLeft: 36.2,
                    }} 
                    alt="sanlam"
                  />
                </div>
            </Row>
            </Col>
          </Row>
          <Row>
            <hr
              style={{
                color: "white",
                height: 2,
                width: "97%",
                margin: "auto",
              }}
            />
          </Row>
          <Row className="secondary-container p-3 ">
          <Row className="secondary-container p-3 ">
            <Col lg={7}>
                <Row>
                    <Col lg={3} style={{textAlign:"right"}}>
                    <img src={Car} style={{
                    width:"60%",
                  }} alt="box" />
                  </Col>
                  <Col lg={9}>
                  <h4
                  style={{
                    fontSize: "20px",
                    color: "#2F2E80",
                    fontWeight: "bold",
                  }}
                >
                  Motor Vehicle Insurance Cover
                </h4>
                  <div style={{display:'flex'}}>
                  <div className={`${width < 770 ? "mt-3" : ""}`}>
                    {data2.map((d) => (
                      <p style={{ color: "#2F2E80", paddingRight: "13%", marginTop:"8px" }}>
                        {d}
                      </p>
                    ))}
                  </div>
                </div>
                  </Col>
                </Row>
              </Col>

              <Col
                lg={5}
                className="d-flex justify-content-center align-items-center"
               > 
                <div className="whiteoverlay_card pl-5 pr-5 pt-4 pb-4 d-flex align-items-center flex-column"  style={{height:"100%"}}>
                <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                   Private Motor Insurance
                </h4>
              </Row>
              <Row>
                <h6
                  style={{
                    fontSize: "11pt",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Cover options to choose from
                </h6>
              </Row>
                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
              <Row style={{marginTop:"8px"}}>
                <h6
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginBottom: "1px",
                    marginTop:"1px"
                  }}
                >
                   {/* Comprehensive claim free Vehicle  */}
                   Comprehensive  
                </h6>
              </Row>
              <Row>
                <h6
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginBottom: "8px",
                    marginTop:"1px"
                  }}
                >
                   From: TSh 250,000 
                </h6>
              </Row>
              <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
              <Row style={{marginTop:"8px"}}>
                <h6
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginBottom: "1px",
                    marginTop:"1px"
                  }}
                >
                  3rd party fire and theft
                </h6>
              </Row>
              <Row>
                <h6
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginBottom: "8px",
                    marginTop:"1px"
                  }}
                >
                   From: TSh 200,000
                </h6>
              </Row>
              <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
              <Row style={{marginTop:"8px"}}>
                <h6
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginBottom: "1px",
                    marginTop:"1px"
                  }}
                >
                   3rd party 
                </h6>
              </Row>
              <Row>
                <h6
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginBottom: "8px",
                    marginTop:"1px"
                  }}
                >
                   From: TSh 100,000
                </h6>
              </Row> 
              <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                /> 
              <Row>
                    <Col lg={4} style={{ paddingLeft: "25px" }}>
                      <div
                        className={`card__footer__bottom  ${
                          width < 430
                            ? "mt-2 text-center"
                            : "mt-4 d-flex justify-content-start"
                        }`}
                      >
                        {width > 500 && (
                          <div
                            className={
                              width < 430 ? `d-flex justify-content-center` : ""
                            }
                          >
                            <BodyButton
                              color="white"
                              bg={width > 770 ? "#FBD105" : "#FBD105"}
                              name="Buy Cover Now"
                              onClick={handleSubmit}
                              size={16}
                              weight={700}
                              marginT={0}
                              width={width > 430 ? 180 : 100}
                              borderTRR={25}
                              borderBLR={25}
                              borderBRR={25}
                              borderTLR={25}
                              black={width > 430 ? false : true}
                              isHeader={true}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>  
                  {/* <Row
                  className="justify-content-center"
                  style={{
                    fontSize: "11pt",
                    color: "white",
                    textAlign: "center",
                    marginTop: "8px",
                    marginBottom: "25px",
                    fontWeight: "bold",
                    paddingLeft:"11px"
                  }}
                >
              From: TSh 875pa
            </Row> */}
            <div style={{ height: "20px" }} />
            <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
            <Row
                className="justify-content-center"
                style={{
                  fontSize: "8pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  padding:"14px",
                  fontWeight:"bold"
                }}
              >
                By opting to buy insurance, Letshego will share the required
                information with the insurer to facilitate your policy purchase.
              </Row>
              <Row>
              <h6
                  style={{
                    color: "white",
                    fontSize: "8pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginTop:"5px"
                  }}
                >Policies underwritten by</h6>
                </Row>
                <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={sanlam}
                    style={{
                      marginBottom: 20,
                      width: "87px",
                    }} 
                    alt="sanlam"
                  />
                </div>
            </Row>
                </div>
              </Col>
          </Row>
          </Row>
        </div>
      </Row>
    </Col>
    {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{height: 100, width: 100}}>
            <CircularProgressbar styles={buildStyles({textColor: '#fbcf2c', pathColor:'#fbcf2c'})} value={countDown} text={`${countDown}%`} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card2;

const data2 = [
  "Your vehicle plays an important role in your daily life. If you need to replace it because of loss, theft or damage, the right insurance can give you peace of mind that you can do so timely and properly. Let us help you to minimise the financial burden when things go wrong.",
];
 
 
 